import { FC } from 'react'
import { Layout } from 'src/components/site/Layout'
import { Section } from 'src/components/parts/Section'
import { Container } from 'src/components/parts/Container'
import { Paragraph } from 'src/components/parts/Paragraph'
import { Breadcrumb } from 'src/components/groups/Breadcrumb'
import { SectionHeading } from 'src/components/parts/SectionHeading'
import { PageHeading } from 'src/components/parts/PageHeading'
import { TextLink } from 'src/components/parts/TextLink'
import { Text } from 'src/components/parts/Text'
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'

const ContactSuccessPage: FC = () => {
  const breadcrumbItems = [
    {
      title: 'HOME',
      path: '/',
    },
    {
      title: 'CONTACT',
      path: '/contact/',
    },
    {
      title: 'THANK YOU!!',
      path: '/contact/success/',
    },
  ]

  return (
    <Layout
      title="THANK YOU!!"
      description="KOKONIARUKOTO.へのお問い合わせありがとうございます。"
      noindex
      ldType="ContactChild"
    >
      <Section>
        <Container>
          <PageHeading heading="THANK YOU!!" />
        </Container>
      </Section>
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Section>
        <Container small>
          <SectionHeading heading="送信完了" />
          <Paragraph>
            <Text text="KOKONIARUKOTO.にお問い合わせ頂き、誠にありがとうございます。" />
            <Text text="内容を確認して、2営業日以内にお返事致しますので、しばらくお待ち下さい。" />
          </Paragraph>
          <Paragraph>
            <TextLink to="/" text="HOMEへ戻る" css={{ marginRight: 'auto' }} />
          </Paragraph>
        </Container>
      </Section>
    </Layout>
  )
}

export default ContactSuccessPage
